<template>
  <div class="panel">
    <div class="header">基本信息</div>
    <div
      style="display: flex; justify-content: space-between; flex: 1; gap: 10px"
    >
      <div class="table">
        <div class="cell">
          <div>当前故障</div>
          <div>{{ response.fault_name ? response.fault_name : '--' }}</div>
        </div>
        <!--   <div class="cell">
                    <div>起始时间</div>
                    <div>{{response.start_fault_time?response.start_fault_time:'--'}}</div>
                </div> -->
        <div class="cell">
          <div>品牌</div>
          <div>
            {{
              response.v_elevator_brand_name
                ? response.v_elevator_brand_name
                : '--'
            }}
          </div>
        </div>
        <div class="cell">
          <div>电梯类型</div>
          <div>
            {{
              response.elevator_type_name ? response.elevator_type_name : '--'
            }}
          </div>
        </div>
        <div class="cell">
          <div>上次维保日期</div>
          <div>
            {{
              response.last_maintain_date ? response.last_maintain_date : '--'
            }}
          </div>
        </div>
        <div class="cell">
          <div>下次年检时间</div>
          <div>
            {{ response.next_inspect_date ? response.next_inspect_date : '--' }}
          </div>
        </div>
      </div>
      <div class="table">
        <div class="cell">
          <div>注册代码</div>
          <div>
            {{ response.v_equipment_code ? response.v_equipment_code : '--' }}
          </div>
        </div>
        <div class="cell">
          <div>平均每日运行次数</div>
          <div>
            {{
              response.bi_run_count
                ? calcAvgValue(response.bi_run_count, response.cumulative_days)
                : '--'
            }}次
          </div>
        </div>
        <div class="cell">
          <div>平均每日运行距离</div>
          <div>
            {{
              response.bi_run_distance_count
                ? calcAvgValue(
                    response.bi_run_distance_count,
                    response.cumulative_days
                  ) / 1000
                : '--'
            }}Km
          </div>
        </div>
        <div class="cell">
          <div>平均每日开关门次数</div>
          <div>
            {{
              response.bi_door_count
                ? calcAvgValue(response.bi_door_count, response.cumulative_days)
                : '--'
            }}次
          </div>
        </div>
        <div class="cell">
          <div>钢丝绳每日折弯次数</div>
          <div>
            {{
              response.bi_bend_count
                ? calcAvgValue(response.bi_bend_count, response.cumulative_days)
                : '--'
            }}次
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      params: {
        elevatorCode: '',
        elevatorId: '',
      },
      response: {},
    }
  },
  mounted() {},
  methods: {
    getInfoBy(elevatorCode, registorNum) {
      //TO-DO
    },
    updateByInfo(info) {
        if (!info) {
          this.response = ''
        } else {
          this.response = info
        }
    //   this.response = info
    },
    calcAvgValue(total, period) {
      return period > 0 ? parseInt(total / period) : 0
    },
  },
  watch: {
    // response:function(newObj,oldObj){
    //     console.log(newObj,oldObj,2222222);
    // }
    response: {
      immediate: true,
      handler(val) {
        this.response = val
      },
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/common/styles/handle.scss';
.panel {
  display: flex;
  flex-direction: column;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 1);
  @include border_color(panelBorderColor);
  @include background_color(panelBgColor);
}

.header {
  line-height: 45px;
  height: 45px;
  font-size: 16px;
  @include font_color(singleMonitorTableHeaderTextColor);
  @include background_color(singleMonitorTableHeaderBgColor);
  padding: 0 20px;
  font-weight: 600;
}

.table {
  width: 50%;
  @include background_color(singleMonitorTableBgColor);
  @include font_color(singleMonitorTableTextColor);
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  //gap: 10px;
  justify-content: space-around;

  .cell {
    display: flex;
    justify-content: space-between;

    div {
      width: 50%;
      font-size: 14px;
    }

    div:first-of-type {
      font-weight: 600;
    }
  }
}
</style>