<template>
    <div class="panel">
        <div>运行热力图</div>
        <el-button  type="primary" size="mini"@click="showHeatMap">查看</el-button>
        <Modal footer-hide :mask="true" :mask-closable="false" :scrollable="true" v-model="heatMapShow" width="80" class="model">
            <div ref="chartContainer" style="height: 100%; ;padding: 0 20px; display:flex;align-items: center;">
                <div style="flex:1;">
                    <highcharts class="highcharts" v-if="optionHeatMap.series[0].data.length !== 0" :options="optionHeatMap" style="margin-top: 20px;"> </highcharts>
                    <p class="nodata" v-if="optionHeatMap.series[0].data.length === 0">
                        <!-- <svg-icon :icon-class="'encourage'" class="svg-icon_home_no_data" /><br /> -->
                        没有热力图数据
                    </p>
                </div>
            </div>
        </Modal>
    </div>
</template>
<script>
import { getElevatorHeatMapStatistics } from '@/api/elevator/index.js'
import Moment from 'moment'
import 'moment/locale/zh-cn'
Moment.locale('zh-cn')

import Highcharts from 'highcharts'
import HeatMap from 'highcharts/modules/heatmap'
HeatMap(Highcharts)

export default {
    data() {
        return {

            //热力图
            heatmapForm: {
                startDate: Moment()
                    .subtract(7, 'days')
                    .format('YYYY-MM-DD'),
                endDate: Moment().format('YYYY-MM-DD'),
                compireCodes: '',
            },
            selCodes: '',
            compireFlag: false,
            heatMapShow: false,
            // 梯楼层热力图
            optionHeatMap: {
                chart: {
                    type: 'heatmap',
                    marginTop: 40,
                    marginBottom: 80,
                    plotBorderWidth: 1,
                    height: 600,
                    backgroundColor: 'none',
                },
                title: {
                    text: '电梯停靠热力图',
                    style: {
                        color: '#000',
                    },
                },
                xAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: '#000',
                        },
                    },
                },
                yAxis: {
                    categories: [],
                    labels: {
                        style: {
                            color: '#000',
                        },
                    },
                },
                colorAxis: {
                    min: 0,
                    minColor: '#CAE2FB',
                    maxColor: '#2d8cf0',
                    labels: {
                        style: {
                            color: '#000',
                        },
                    },
                },
                credits: {
                    enabled: false, // 禁用版权信息
                },
                legend: {
                    align: 'right',
                    layout: 'vertical',
                    margin: 0,
                    verticalAlign: 'top',
                    y: 25,
                    symbolHeight: 280,
                },
                tooltip: {
                    formatter: function() {
                        return '<b>' + this.series.xAxis.categories[this.point.x] + '在<b>' + this.series.yAxis.categories[this.point.y] + '</b>' + '</b> 停 <br><b>' + this.point.value + '</b> 次 <br>'
                    },
                },
                series: [{
                    name: 'Sales per employee',
                    borderWidth: 1,
                    data: [],
                    dataLabels: {
                        enabled: true,
                        color: '#000000',
                        style: {
                            fontSize: '16px',
                            fontFamily: 'mircosoft'
                        }
                    },
                }, ],
            },
        }
    },

    methods: {
        getInfoBy(elevatorCode, registorNum) {
            //TO-DO
        },
        updateByInfo(info) {
            this.response = info
        },
        showHeatMap() {
            if (!this.response.v_elevator_code) return
            this.heatMapShow = true
            this.updateHeatmap(this.heatmapForm.startDate, this.heatmapForm.endDate, this.response.v_elevator_code)
        },
        updateHeatmap(startDate, endDate, codes) {
            let that = this
            getElevatorHeatMapStatistics({ startDate: startDate, endDate: endDate, elevatorCode: codes }).then(res => {
                const dateArr = []
                const codesArr = []
                const floorArr = []
                const xyPoints = []
                const hashMap = {}

                if (Array.isArray(res.info) && res.info.length > 0) {
                    if (that.heatmapForm.compireCodes.split(',').length > 1) {
                        //多梯
                        this.optionHeatMap.series[0].data = []
                        this.optionHeatMap.xAxis.categories = []
                        this.optionHeatMap.yAxis.categories = []
                    } else {
                        //单梯
                        const obj = res.info[0]
                        obj.floorNumberCountInfo.forEach(function(fObj, idx) {
                            fObj.countDateInfo.forEach(function(cObj, idx) {
                                if (dateArr.indexOf(cObj.countDate) < 0) dateArr.push(cObj.countDate)
                                hashMap[fObj.floorNumber + '_' + cObj.countDate] = cObj.countStop
                            })
                            floorArr.push(fObj.floorNumber)
                        })
                        dateArr.sort(function(a, b) {
                            return a > b ? 1 : -1
                        })

                        for (let i = 0; i < floorArr.length; i++) {
                            for (let j = 0; j < dateArr.length; j++) {
                                let count = hashMap[floorArr[i] + '_' + dateArr[j]]
                                xyPoints.push([i, j, count > 0 ? count : 0])
                            }
                        }

                        // debugger

                        this.optionHeatMap.series[0].data = xyPoints
                        this.optionHeatMap.xAxis.categories = floorArr.map(function(obj) {
                            return obj + 'F'
                        })
                        this.optionHeatMap.xAxis.title = '日期'
                        this.optionHeatMap.yAxis.categories = dateArr
                        this.optionHeatMap.yAxis.title = '楼层'
                    }
                    this.optionHeatMap.chart.height = this.$refs.chartContainer.offsetHeight - 60 < 380 ? 380 : this.$refs.chartContainer.offsetHeight - 60
                } else {
                    this.optionHeatMap.series[0].data = []
                    this.optionHeatMap.xAxis.categories = []
                    this.optionHeatMap.yAxis.categories = []
                }
            })

        }
    }
}
</script>
<style lang="scss" scoped>
@import "@/common/styles/handle.scss";
.panel {
    border:1px solid #fff;
    height: 100%;
    font-size: 18px;
    font-weight: 600;
    color: #383C4B;
    padding: 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include background_color(singleMonitorHeatMapBgColor);
    @include font_color(singleMonitorHeatMapTextColor);
    @include border_color(panelBorderColor);
    @include background_color(panelBgColor);
}
</style>